@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
	transition: 3ms ease;
}

.homepage-container {
	padding-top: 10px;
}

.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.homepage-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.homepage-title {
	width: 85% !important;
}

.homepage-subtitle {
	width: 85% !important;
}

.homepage-first-area-right-side {
	display: flex;
	align-items: center;
}

.homepage-image-container {
	width: 370px;
	height: 400px;
}

.homepage-image-wrapper {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 10%;
	transform: rotate(3deg);
}

.homepage-image-wrapper .homepage-image {
	width: 100%;
}

.homepage-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}

.homepage-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-after-title {
	display: flex;
	/* background: red; */
}

.homepage-projects {
	flex-basis: 300px;
	padding-top: 40px;
	/* text-align: center; */
}
.homepage-projects h2{
	width: 100%;
	font-size: 34px;
	margin: 0;
	margin-top: 40px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
}



.homepage-articles {
	flex-basis: 900px;
	padding-top: 30px;
	margin-left: -30px;
	
}

.homepage-articles h2{
	text-align: center;
	font-size: 30px;
	margin: 0;
	margin-top: 40px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
	/* background: red; */
}

.homepage-article {
	padding-bottom: 5px;
}

.homepage-works {
	display: flex;
	align-items: center;
	flex-basis: 600px;
	padding-top: 30px;
}
.resume-download{
	font-size: 1.2rem;
	text-decoration: none;
	border: var(--link-color);
	color: #fff;
	background-color: var(--link-color);
	padding: 1rem 2rem;
	border-radius: 50px;
	transition: all 0.3s ease-in-out;
}

.resume-download:hover{
	background-color: #fff;
	color: var(--link-color);
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
	.homepage-first-area {
		flex-direction: column;
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-title {
		width: 100% !important;
		margin-top: 20px;
		order: 2;
	}

	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 3;
	}

	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
	}

	.homepage-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: 10px;
		order: 1;
	}

	.homepage-after-title {
		flex-direction: column;
	}

	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-articles {
		flex-basis: auto;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
	.resume-download {
		font-size: 15px;
		padding: 15px 15px;
	}
}
